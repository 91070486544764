
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class TheContainerHeader extends Vue {
  @Prop({ default: false })
  isSticky!: boolean

  @Prop({ default: false })
  isMobile!: boolean

  public beforeClick() {
    if (this.isMobile) {
      const collapseElementList = document.querySelectorAll('.collapse')
      // @ts-ignore
      const collapseList = [...collapseElementList].map(
        // @ts-ignore
        (collapseEl) => new bootstrap.Collapse(collapseEl)
      )
    }
  }
}
