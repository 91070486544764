import { render, staticRenderFns } from "./TheHeader.vue?vue&type=template&id=5106fa70&scoped=true&"
import script from "./TheHeader.vue?vue&type=script&lang=ts&"
export * from "./TheHeader.vue?vue&type=script&lang=ts&"
import style0 from "./TheHeader.vue?vue&type=style&index=0&id=5106fa70&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5106fa70",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LayoutsHeaderTheTopHeader: require('/usr/src/nuxt-app/components/layouts/header/TheTopHeader.vue').default,LayoutsHeaderTheContainerHeader: require('/usr/src/nuxt-app/components/layouts/header/TheContainerHeader.vue').default})
