
import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class DefaultLayout extends Vue {
  public head() {
    return {
      htmlAttrs: {
        lang: 'it',
      },
      script: [
        {
          hid: 'iub_banner',
          type: 'text/javascript',
          src: '/iub_banner.js',
        },
        {
          hid: 'iub_banner-link',
          type: 'text/javascript',
          async: true,
          charset: 'UTF-8',
          src: 'https://cdn.iubenda.com/cs/iubenda_cs.js',
        },

        {
          hid: 'iub_consent',
          type: 'text/javascript',
          src: '/iub_consent.js',
        },
        {
          hid: 'iub_consent-link',
          type: 'text/javascript',
          async: true,
          charset: 'UTF-8',
          src: 'https://cdn.iubenda.com/cons/iubenda_cons.js',
        },
      ],
    }
  }
}
