import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _edeaa610 = () => interopDefault(import('../pages/chi-siamo.vue' /* webpackChunkName: "pages/chi-siamo" */))
const _29159750 = () => interopDefault(import('../pages/convegni/index.vue' /* webpackChunkName: "pages/convegni/index" */))
const _13b68293 = () => interopDefault(import('../pages/corsi/index.vue' /* webpackChunkName: "pages/corsi/index" */))
const _e4fa33ba = () => interopDefault(import('../pages/ivg-associati.vue' /* webpackChunkName: "pages/ivg-associati" */))
const _68f6ca49 = () => interopDefault(import('../pages/lavora-con-noi.vue' /* webpackChunkName: "pages/lavora-con-noi" */))
const _2b4f3040 = () => interopDefault(import('../pages/news-eventi/index.vue' /* webpackChunkName: "pages/news-eventi/index" */))
const _dc6e56da = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _482400f4 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _275ef608 = () => interopDefault(import('../pages/convegni/_slug.vue' /* webpackChunkName: "pages/convegni/_slug" */))
const _11ffe14b = () => interopDefault(import('../pages/corsi/_slug.vue' /* webpackChunkName: "pages/corsi/_slug" */))
const _29988ef8 = () => interopDefault(import('../pages/news-eventi/_slug.vue' /* webpackChunkName: "pages/news-eventi/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'active',
  scrollBehavior,

  routes: [{
    path: "/chi-siamo",
    component: _edeaa610,
    name: "chi-siamo"
  }, {
    path: "/convegni",
    component: _29159750,
    name: "convegni"
  }, {
    path: "/corsi",
    component: _13b68293,
    name: "corsi"
  }, {
    path: "/ivg-associati",
    component: _e4fa33ba,
    name: "ivg-associati"
  }, {
    path: "/lavora-con-noi",
    component: _68f6ca49,
    name: "lavora-con-noi"
  }, {
    path: "/news-eventi",
    component: _2b4f3040,
    name: "news-eventi"
  }, {
    path: "/privacy-policy",
    component: _dc6e56da,
    name: "privacy-policy"
  }, {
    path: "/",
    component: _482400f4,
    name: "index"
  }, {
    path: "/convegni/:slug",
    component: _275ef608,
    name: "convegni-slug"
  }, {
    path: "/corsi/:slug",
    component: _11ffe14b,
    name: "corsi-slug"
  }, {
    path: "/news-eventi/:slug?",
    component: _29988ef8,
    name: "news-eventi-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
