import { ActionTree } from 'vuex'

type VGState = {
  layout?: any
  // paginator?: any
  // searchparams?: any
  // typesense?: any
}

export const state = (): VGState => ({})

export type RootState = ReturnType<typeof state>

export const actions: ActionTree<RootState, RootState> = {
  // async nuxtServerInit({ dispatch }) {
  //   await dispatch('searchparams/initFilters')
  //   await dispatch('searchparams/setFilters', this.$router.currentRoute.query)
  //   await dispatch('searchparams/initFrontData')
  // },
}
