
import { Component, Vue, Getter } from 'nuxt-property-decorator'

@Component
export default class TheHeader extends Vue {
  @Getter('layout/offset') offset!: number
  @Getter('layout/isSticky') isSticky!: boolean
  @Getter('layout/isMobile') isMobile!: boolean

  public mounted(): void {
    if (process.client) {
      window.addEventListener('scroll', this.onScroll)
      window.addEventListener('resize', this.setWindowWidth)
      this.setWindowWidth()
    }
  }

  public beforeDestroy(): void {
    if (process.client) {
      window.removeEventListener('scroll', this.onScroll)
      window.removeEventListener('resize', this.setWindowWidth)
    }
  }

  public setWindowWidth() {
    this.$store.dispatch('layout/changeWidth', window.innerWidth)
  }

  public onScroll() {
    this.$store.dispatch('layout/changeY', window.pageYOffset)
    if (window.pageYOffset === 0) {
      this.$store.dispatch('layout/changeHasNotScrolled')
    } else {
      this.$store.dispatch('layout/changeHasScrolled')
    }
  }
}
