export const ClassesMeta = () => import('../../components/classes/meta.ts' /* webpackChunkName: "components/classes-meta" */).then(c => wrapFunctional(c.default || c))
export const ElementsFileUpload = () => import('../../components/elements/FileUpload.vue' /* webpackChunkName: "components/elements-file-upload" */).then(c => wrapFunctional(c.default || c))
export const ElementsFormCorsi = () => import('../../components/elements/FormCorsi.vue' /* webpackChunkName: "components/elements-form-corsi" */).then(c => wrapFunctional(c.default || c))
export const ElementsInputWithValidation = () => import('../../components/elements/InputWithValidation.vue' /* webpackChunkName: "components/elements-input-with-validation" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheAbout = () => import('../../components/elements/TheAbout.vue' /* webpackChunkName: "components/elements-the-about" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheBreadcrumbs = () => import('../../components/elements/TheBreadcrumbs.vue' /* webpackChunkName: "components/elements-the-breadcrumbs" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheCarousel = () => import('../../components/elements/TheCarousel.vue' /* webpackChunkName: "components/elements-the-carousel" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheError = () => import('../../components/elements/TheError.vue' /* webpackChunkName: "components/elements-the-error" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheMap = () => import('../../components/elements/TheMap.vue' /* webpackChunkName: "components/elements-the-map" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheNotice = () => import('../../components/elements/TheNotice.vue' /* webpackChunkName: "components/elements-the-notice" */).then(c => wrapFunctional(c.default || c))
export const ElementsThePageTitleSection = () => import('../../components/elements/ThePageTitleSection.vue' /* webpackChunkName: "components/elements-the-page-title-section" */).then(c => wrapFunctional(c.default || c))
export const ElementsThePaginator = () => import('../../components/elements/ThePaginator.vue' /* webpackChunkName: "components/elements-the-paginator" */).then(c => wrapFunctional(c.default || c))
export const ElementsTheSharer = () => import('../../components/elements/TheSharer.vue' /* webpackChunkName: "components/elements-the-sharer" */).then(c => wrapFunctional(c.default || c))
export const HomeTheAbout = () => import('../../components/home/TheAbout.vue' /* webpackChunkName: "components/home-the-about" */).then(c => wrapFunctional(c.default || c))
export const HomeTheArticoli = () => import('../../components/home/TheArticoli.vue' /* webpackChunkName: "components/home-the-articoli" */).then(c => wrapFunctional(c.default || c))
export const HomeTheConvegni = () => import('../../components/home/TheConvegni.vue' /* webpackChunkName: "components/home-the-convegni" */).then(c => wrapFunctional(c.default || c))
export const HomeTheCorsi = () => import('../../components/home/TheCorsi.vue' /* webpackChunkName: "components/home-the-corsi" */).then(c => wrapFunctional(c.default || c))
export const HomeThePartners = () => import('../../components/home/ThePartners.vue' /* webpackChunkName: "components/home-the-partners" */).then(c => wrapFunctional(c.default || c))
export const ElementsSkeletonsTheConvegni = () => import('../../components/elements/skeletons/TheConvegni.vue' /* webpackChunkName: "components/elements-skeletons-the-convegni" */).then(c => wrapFunctional(c.default || c))
export const ElementsSkeletonsTheCorsi = () => import('../../components/elements/skeletons/TheCorsi.vue' /* webpackChunkName: "components/elements-skeletons-the-corsi" */).then(c => wrapFunctional(c.default || c))
export const LayoutsFooterTheFooter = () => import('../../components/layouts/footer/TheFooter.vue' /* webpackChunkName: "components/layouts-footer-the-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheContainerHeader = () => import('../../components/layouts/header/TheContainerHeader.vue' /* webpackChunkName: "components/layouts-header-the-container-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheHeader = () => import('../../components/layouts/header/TheHeader.vue' /* webpackChunkName: "components/layouts-header-the-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeaderTheTopHeader = () => import('../../components/layouts/header/TheTopHeader.vue' /* webpackChunkName: "components/layouts-header-the-top-header" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
