export default function ({ $axios, app, route, redirect, store, req, res }) {
  $axios.onRequest((config) => {
    config.headers.Authorization = 'Bearer ' + process.env.TOKEN
    // config.headers.Authorization = 'Bearer ' + process.env.TOKEN
  })
  // $axios.onRequest((config) => {
  // })
  // $axios.onResponse((response) => {
  // })
  // $axios.onError((error) => {
  // })
}
