import { GetterTree } from 'vuex'
import { RootState } from '~/store'
import { LayoutState } from '~/store/layout/state'

const getters: GetterTree<LayoutState, RootState> = {
  offset: (state) => state.offsetY,
  isSticky: (state) => state.offsetY > 53,
  width: (state) => state.deviceWidth,
  hasScrolled: (state) => state.scrolled,
  isMobile: (state) => state.deviceWidth < 992,
}

export default getters
